import { createAsyncThunk } from "@reduxjs/toolkit";

import { retrieveAllUsers } from "../../user/use-cases";
import { retrieveAllCategorieProduit } from "../../categorie-produit/use-cases";
import { retreiveAllArticles } from "../../article/use-cases";
import { retrieveAllEntreprises } from "../../entreprise/use-cases";
import { retrieveAllChantiers } from "../../chantier/use-cases";
import { retreiveAllInterlocuteurs } from "../../interlocuteur/use-cases";

export const fetchRequiredData = createAsyncThunk<void, void, { extra: any }>(
    "[AUTH] fetchRequiredData",
    async (_, thunk) => {
        thunk.dispatch(retrieveAllEntreprises());
        thunk.dispatch(retrieveAllChantiers());
        thunk.dispatch(retreiveAllInterlocuteurs());
        thunk.dispatch(retreiveAllArticles());
        thunk.dispatch(retrieveAllUsers());
        thunk.dispatch(retrieveAllCategorieProduit());
    });