import { useSelector } from 'react-redux';
import { ViewCard } from '../../../../App/app/common/layout/components';
import { CustomTable, CustomTableToolbar } from '../../../../App/app/common/custom-table';
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { retrieveAllFactures, retrieveOneAvoir,} from "../../../Domain/Application/use-cases";
import { selectAllFactures, selectAvoir } from "../../../Domain/Application/selectors";
import { Autocomplete, Button, Grid,  Stack, TextField } from '@mui/material'; //InputLabel, MenuItem, Select,
import { useCallback, useEffect, useState } from 'react'; //SyntheticEvent, 
import { ColumnEntity } from '../../../../App/app/common/custom-table/Table.entities';
import { FactureTableStatusCell, Status } from '../facture/facture-list/FactureTableStatusCell';
import { IconByEntrepriseType } from '../IconByEntrepriseType';
import { selectAllLabelsAndIds, selectEntrepriseById } from '../../../../App/core/entreprise/selectors';
import { EntityId } from '@reduxjs/toolkit';
import { Avoir, Facture } from '../../../Domain/Model';
import { TaskAlt } from '@mui/icons-material';
import { MultiFacturesValidatePaymentDialog } from '../commun';

function SaisieEncaissement() {
    
    const dispatch = useAppDispatch();
 
    let factures = useSelector(selectAllFactures).filter((facture) => 
      facture.type === "client" && 
      facture.numeroFacture !== '' && 
      facture.numeroFacture !== 'Brouillon' &&
      (facture.status === "EMITTED" || facture.status === "EDITED")
    );

    const [selectedFactures, setSelectedFactures] = useState<Facture[]>([]);
    const [clientfilters, setclientfilters] = useState({  client : ""  });
    const entreprises = useSelector(selectAllLabelsAndIds);
    const [totalFactures,setTotalFactures] = useState(0);
    const [totalAvoirs,setTotalAvoirs] = useState(0);
    const [totalSolde,setTotalSolde] = useState(0);
    const [totalSelectedFactures,setTotalSelectedFactures] = useState(0);
    const [avoirsList, setAvoirsList] = useState<Avoir[]>([]);
    const avoirs = useSelector(selectAvoir);

    const [dialogsState, setDialogsState] = useState({
      validate: false,
      relance: false,
      emit: false,
    });

    const dataClient = useSelector(selectEntrepriseById(clientfilters.client));
      
    const loadFactures = useCallback(
      async () => {  
          try {
            if(clientfilters.client !== "") {
              await dispatch(retrieveAllFactures({   ...clientfilters }));
            }
          } catch (err) {
            console.warn("Erreur lors de la récupération des factures", err);
          }
        },
        [dispatch,clientfilters]
    );

    useEffect(() => {
      loadFactures();

      const fetchAvoir = async () => {
        await dispatch(retrieveOneAvoir("all"));
      };
      if ( Boolean(factures) ) fetchAvoir();

      const currentClientAvoir = avoirs.filter((a) => {
        return a.ref_facture.client?.id === clientfilters.client && !a.used_at
      });

      let totalAvoirNum = 0;
      currentClientAvoir.map(a => totalAvoirNum += Number(a.net_a_deduire?.value));
      setTotalSolde(Number(dataClient?.solde_reglement_client ? dataClient?.solde_reglement_client : 0));
      setTotalAvoirs(totalAvoirNum);
      setAvoirsList(currentClientAvoir);
      if(dataClient && dataClient.solde_reglement_client) {
        let totalFacturesTtc = 0;
        factures.forEach((facture:any) => { 
          if(facture.client.id === dataClient.id) { totalFacturesTtc+=facture.totalTTC; }
        });
        setTotalFactures(totalFacturesTtc);

      }

      return () => {
      };
    }, [loadFactures,clientfilters,selectedFactures,dataClient]);

 

    const onSelectRows = (selectedFacturesId:EntityId[]) => { 
      changeSelectedFactures(selectedFacturesId);
    };

    function changeSelectedFactures(selectedFacturesId:EntityId[]) {
 
        if(selectedFacturesId.length > 0 && clientfilters.client !== "") {
          let totalselectedFacturesId = 0;
          let selectedFactureByIdArray:any = [];
          selectedFacturesId.forEach((factureId:EntityId) => {
            const selectedFactureById = factures.filter((facture) => 
              facture.id === factureId && facture.client.id === clientfilters.client
            );
            selectedFactureById.forEach((facture:any) => { totalselectedFacturesId+=facture.totalTTC;})
            selectedFactureByIdArray = [...selectedFactureByIdArray, selectedFactureById[0]];
          });

          setSelectedFactures(selectedFactureByIdArray);
          setTotalSelectedFactures(totalselectedFacturesId);
        }
        else { setSelectedFactures([]);  setTotalSelectedFactures(0); }
      }

      const onClientSelection = (event:any, values:any) => {
        if(values && values.id) {
          setclientfilters({  client : values.id });
        }
        else { setclientfilters({  client : "" }); }
        changeSelectedFactures([]);
        setTotalAvoirs(0);
        setTotalFactures(0);
        setTotalSelectedFactures(0);
      };

      const callValidatePay = () => {
        const currentState = dialogsState["validate"];
        const newState = !currentState;
        setDialogsState({ ...dialogsState, ["validate"]: newState });
      }

      const columns: ColumnEntity[] = [
        {
          field: "status",
          headerName: "",
          width: 40,
          renderCell: (param) =>
            Object.values(Status).includes(param as Status) ? (
              <FactureTableStatusCell param={param} />
            ) : (
              param
            ),
        },
        {
          field: "numeroFacture",
          headerName: "N°",
          width: 110,
          sortable: true,
          resizable: true,
        },
        {
          field: "type",
          headerName: "",
          sortable: true,
          width: 40,
          renderCell(params: "client" | "fournisseur"): React.ReactChild {
            if (Boolean(params))
              return <IconByEntrepriseType entrepriseType={params} />;
            return <></>;
          },
        },
        {
          field: "dateDeFacturation",
          headerName: "Date",
          width: 120,
          sortable: true,
          resizable: true,
        },
        {
          field: "client.name",
          headerName: "Client",
          flexGrow: 1,
          sortable: true,
        },
        {
          field: "bons.length",
          headerName: "Bons",
          width: 80,
          sortable: true,
          resizable: true,
        },
        {
          field: "totalFraisAutorouteE",
          headerName: "Frais Auto.",
          width: 110,
          sortable: true,
          resizable: true,
        },
        {
          field: "totalHTe",
          headerName: "HT",
          width: 150,
          sortable: true,
          resizable: true,
        },    
        {
          field: "totalTvae",
          headerName: "TVA",
          width: 110,
          sortable: true,
          resizable: true,
        },
        {
          field: "totalTTCe",
          headerName: "TTC",
          width: 150,
          sortable: true,
          resizable: true,
        },
        {
          field: "reglement.montante",
          headerName: "Encaissé",
          width: 150,
          sortable: true,
          resizable: true,
        },
      ];
    
      if(clientfilters.client === "") {
        factures = factures.filter((facture) => facture.client.id === "");
      }

      const table_factures = JSON.parse(JSON.stringify(factures));
     
      table_factures.forEach((facture:any) => {
        facture.totalTTCe = Number(facture.totalTTC).toFixed(2) + " €";
        if(facture.totalHT) { facture.totalHTe = Number(facture.totalHT).toFixed(2) + " €"; }
        facture.totalTvae = Number(facture.totalTva).toFixed(2) + " €";
        facture.reglement.montante = Number(facture.reglement.montant).toFixed(2) + " €";
        facture.totalFraisAutorouteE = Number(facture.totalFraisAutoroute).toFixed(2) + " €";
      });

      const table_factures_selected = JSON.parse(JSON.stringify(selectedFactures));
    
      table_factures_selected.forEach((facture:any) => {
        if(facture.totalTTC) {  facture.totalTTCe = Number(facture.totalTTC).toFixed(2) + " €";  }
        if(facture.totalHT) { facture.totalHTe = Number(facture.totalHT).toFixed(2) + " €"; }
        facture.totalTvae = Number(facture.totalTva).toFixed(2) + " €";
        facture.reglement.montante = Number(facture.reglement.montant).toFixed(2) + " €";
        facture.totalFraisAutorouteE = Number(facture.totalFraisAutoroute).toFixed(2) + " €";
      });

      const handleDialogs = (
        buttonId: keyof typeof dialogsState,
        rowData?: Facture
      ) => {
        const currentState = dialogsState[buttonId];
        const newState = !currentState;
        setDialogsState({ ...dialogsState, [buttonId]: newState });
        if (newState) { }
        loadFactures();
        setSelectedFactures([]);  setTotalSelectedFactures(0);
      };

    return (
        <>
            <ViewCard>
            <CustomTableToolbar>
                <Stack direction="row" alignItems="flex-end" gap={2}>
                    <Autocomplete  
                      disablePortal
                      id="client"
                            options={entreprises}
                      sx={{ width: 300 }}
                      onChange={onClientSelection}
                      renderInput={(entreprises) => <TextField {...entreprises} label="Client" />}
                    />
                </Stack>
            </CustomTableToolbar>
            </ViewCard><br />
            <ViewCard>
            <CustomTable
                data={table_factures}
                columns={columns}
                hover={false}
                onSelectRows={onSelectRows}
                height={Number(300)}
            />
            </ViewCard><br />
            <ViewCard>
              <Grid container spacing={0} direction="row"
                sx={{ minHeight: '80px' }}
                alignItems="center"
                justifyContent="center">
                <Grid item xs={2} textAlign="center">En attente :<br /> <b>{totalFactures} €</b></Grid>
                <Grid item xs={2} textAlign="center">Trop-perçu : <br /> <b>{totalSolde} €</b></Grid>
                <Grid item xs={2} textAlign="center">Avoir(s) : <br /> <b>{totalAvoirs} €</b></Grid>
                <Grid item xs={2} textAlign="center">Sélection(s) : <br /> <b>{totalSelectedFactures} €</b></Grid>
               
                {selectedFactures.length > 0 &&
                            <Grid item xs={3} textAlign="center">
                            <Button
                              onClick={callValidatePay}
                              size="small"
                              variant="contained" 
                              color="success" 
                              startIcon={<TaskAlt/>}>
                                      Encaisser
                            </Button>
                          </Grid> 
                }
              </Grid>
            </ViewCard><br />
            <ViewCard>
            <CustomTable
                data={table_factures_selected}
                columns={columns}
                hover={false}
                onSelectRows={onSelectRows}
                height={Number(200)}
                selectable={false}
            />
            </ViewCard>

            {/* Validate Payment Dialog */}
            <MultiFacturesValidatePaymentDialog
              open={dialogsState.validate}
              factures={selectedFactures}
              totalAvoirs={totalAvoirs}
              avoirsList={avoirsList}
              onCloseDialog={() => handleDialogs("validate")}
            />
        </>
    );
}

export default SaisieEncaissement;
