import { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Divider,
    Checkbox
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { DateRangeField } from '../../../../App/app/common/date-picker';
import { setSuccess } from '../../../../App/core/notification/notification.slice';

import { selectPlanningByIds } from '../../../Domain/Planning/selectors';
import { addOnePlanning } from '../../../Domain/Planning/use-cases';
import { validateDates } from '../../../../App/app/common/_helpers';
import { useSelector } from 'react-redux';


interface Props {
    planningId: EntityId[];
    open: boolean;
    checkedSaturday?: boolean;
    checkedSunday?: boolean;

    onClose(event: React.MouseEvent): void;

    onConfirm(): void;
}

function PlanningDuplicateDialog({ planningId, open, onClose, onConfirm }: Props) {
    const dispatch = useAppDispatch();
    const planning = useSelector(selectPlanningByIds(planningId));

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [duplicateDate, setDuplicateDate] = useState('');
    const [checkedSunday, setCheckedSunday] = useState(false);
    const [checkedSaturday, setCheckedSaturday] = useState(false);

    const handleChangeSaturday = () => {
        setCheckedSaturday(!checkedSaturday);
    };
    const handleChangeSunday = () => {
        setCheckedSunday(!checkedSunday);
    };

    const onDateUpdate = (field: string, value: string) => {
        setDuplicateDate(value);
    };

    const onConfirmDuplicateDialog = async () => {
        setIsButtonLoading(true);
        const validDates = validateDates(duplicateDate);
        // What confirmMessage means ? it is either 0 or 1 but do not know what it does and/or how it is used
        let confirmMessage = 0;
        planning.forEach((planningRessource: any) => {
            // remove id from duplicated
            const { id, ids, ...duplicate } = planningRessource;
            // request will be sent if both dates are valid or no dates is selected
            if ( !!validDates ) {
                const { fromDate, toDate } = validDates;
                const startDateArray = fromDate.split('/');
                const startDate = new Date(Number(startDateArray[2]), (Number(startDateArray[1]) - 1), Number(startDateArray[0]));
                const endDateArray = toDate.split('/');
                const endDate = new Date(Number(endDateArray[2]), (Number(endDateArray[1]) - 1), Number(endDateArray[0]));
                const difference = endDate.getTime() - startDate.getTime();
                const TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) + 1;

                for (let dp = 0; dp < TotalDays; dp++) {
                    const duplicateDateTo = new Date(Number(startDateArray[2]), (Number(startDateArray[1]) - 1), Number(startDateArray[0]));
                    const plusOneDay = duplicateDateTo.setDate(duplicateDateTo.getDate() + dp);

                    const dateToDuplicate = new Date(plusOneDay);

                    let goDuplicate = 0;
                    if ( (dateToDuplicate.getDay() === 6 && !checkedSaturday) || (dateToDuplicate.getDay() === 0 && !checkedSunday) ) {
                        goDuplicate = 1;
                    }

                    const dateToDuplicateString = ('0' + dateToDuplicate.getDate()).slice(-2) + '/'
                        + ('0' + (dateToDuplicate.getMonth() + 1)).slice(-2) + '/'
                        + dateToDuplicate.getFullYear();

                    if ( goDuplicate === 0 ) {
                        // ☣️🚩 not waiting for Promise to complete
                        // ☣️🚩 should not mix mapping logic with fetch logic (separation of concerns)
                        // ☣️🚩 should Error be handled by Redux instead of componenet
                        // TODO : Refactor
                        duplicate.date = dateToDuplicateString;
                        const goRes: any = dispatch(addOnePlanning({
                            // we spread ids of planning as we want to replace names of fields by their ids
                            // (as backend require only ids to create a new planning )
                            ...duplicate,
                            ...ids,
                        })).unwrap();
                        if ( goRes.error ) {
                            dispatch(setSuccess(`Erreur de duplication de planning`));
                            onConfirm();
                            confirmMessage = 1;
                        }
                    }
                }
            }

        });
        if ( confirmMessage === 0 ) {
            dispatch(setSuccess(`Planning(s) dupliqué(s)`));
            onConfirm();
        }

        setIsButtonLoading(false);

    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="duplicate-dialog-titre"
            aria-describedby="duplicate-dialog-description"
        >
            <DialogTitle id="duplicate-dialog-titre">Dupliquer</DialogTitle>
            <DialogContent>
                <DialogContentText id="duplicate-dialog-description">
                    Une date ou une période (de - à)
                </DialogContentText>
                <Divider sx={{ my: 2 }}/>
                <DateRangeField fullWidth label="Date" variant="standard" onDateUpdate={onDateUpdate}
                                value={duplicateDate}/>
                <Divider sx={{ my: 2 }}/>
                <Checkbox
                    value={checkedSaturday}
                    onChange={handleChangeSaturday}
                /> Samedi inclus
                <Checkbox
                    value={checkedSunday}
                    onChange={handleChangeSunday}
                /> Dimanche inclus
                <Divider sx={{ my: 2 }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <LoadingButton variant="contained" loading={isButtonLoading} onClick={onConfirmDuplicateDialog}
                               autoFocus>
                    Enregistrer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default PlanningDuplicateDialog;
