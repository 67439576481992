import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isValid, parse } from 'date-fns';
import { Divider, ListItemText, Stack, Typography, } from '@mui/material';
import { RootState } from '../../../../_config/redux';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { CustomTable } from '../../../../App/app/common/custom-table';
import { ColumnEntity } from '../../../../App/app/common/custom-table/Table.entities';
import { dateFormat } from '../../../../App/app/common/_helpers';
import RichTooltip from '../../../../App/app/common/RichTooltip';

import { retrievePlanningByDate } from '../../../Domain/Planning/use-cases';
import { selectPlanningBySearch } from '../../../Domain/Planning/selectors';
import { Planning } from '../../../Domain/Planning/entities/planning.entity';

import PlanningTableActionsCell from './PlanningTableActionsCell';
import { PlanningTableInformedCell } from './PlanningTableInformedCell';
import { PlanningInformedDialog } from '../common';
import { TableListSummary } from "../TableListSummary";


function PlanningTable({ onSelectRows, defaultPlanningDate,  defaultCount, setdefaultCount }: any) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cellHoverAnchorEl, setCellHoverAnchorEl] = useState<HTMLAnchorElement | null>(null);
    const [rowData, setRowData] = useState<Planning | null>(null);
    const [informedDialogOpen, setInformedDialogOpen] = useState(false);

    const planning = useSelector(selectPlanningBySearch);
    const selectedDate = useSelector((state: RootState) => state.planning.date);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        let finalDate = selectedDate;
        if(defaultCount === 0) {   finalDate = defaultPlanningDate; }
        const date = parse(finalDate.trim(), dateFormat, new Date());
        if ( isValid(date) ) {
            dispatch(retrievePlanningByDate({ date: finalDate }))
                .then(() => setIsLoading(false));
        }
        return () => {
            setIsLoading(true);
        };
    }, [dispatch, selectedDate, defaultCount]);

    const columns: ColumnEntity[] = [
        {
            field: 'hasBeenWarned',
            headerName: 'Prévenu',
            width: 60,
            resizable: false,
            sortable: false,
            renderCell: (param) => {
                return 'client' && 'fournisseur' in param ? (
                    <PlanningTableInformedCell
                        client={param.client}
                        fournisseur={param.fournisseur}
                        rowData={rowData}
                        onClickInformed={() => setInformedDialogOpen(true)}
                    />
                ) : (<></>);
            },
        },
        { field: 'client', headerName: 'Client', width: 200, resizable: true, sortable: true },
        { field: 'chantier', headerName: 'Chantier', width: 195, resizable: true, sortable: true },
        { field: 'commandePar', headerName: 'Commandé par', width: 195, resizable: true, sortable: true },
        { field: 'article', headerName: 'Article', width: 190, resizable: true, sortable: true },
        { field: 'fournisseur', headerName: 'Fournisseur', width: 190, resizable: true, sortable: true },
        { field: 'unite', headerName: 'Unité', width: 80, resizable: true, sortable: true },
        { field: 'quantite', headerName: 'Qte', width: 60, resizable: true, sortable: true },
        { field: 'prixAchatHTe', headerName: 'PA HT', resizable: true, sortable: true },
        { field: 'prixVenteHTe', headerName: 'PV HT', resizable: true, sortable: true },
        { field: 'achatHTe', headerName: 'Achat HT', resizable: true, sortable: true },
        { field: 'montantHTe', headerName: 'Montant HT', width: 110, resizable: true, sortable: true },
        { field: 'margee', headerName: 'Marge', width: 95, resizable: true, sortable: true },
    ];

    const onRowDoubleClick = ({ id }: Planning) => navigate(`../${id}`, { state: id });

    const handlePointerEnter = (event: React.BaseSyntheticEvent, rowData: Planning, field: string) => {
        if ( field === 'commandePar' ) {
            setRowData(rowData);
            setCellHoverAnchorEl(event.currentTarget);
            return;
        }

        if ( field === 'hasBeenWarned' ) {
            setRowData(rowData);
            return;
        }
    };

    const handlePointerLeave = () => setCellHoverAnchorEl(null);
    

    const table_planning = JSON.parse(JSON.stringify(planning));
 
    table_planning.forEach((plan:any) => {
        plan.prixAchatHTe = Number(plan.prixAchatHT).toFixed(2) + " €";
        plan.prixVenteHTe = Number(plan.prixVenteHT).toFixed(2) + " €";
        plan.achatHTe = Number(plan.achatHT).toFixed(2) + " €";
        plan.montantHTe = Number(plan.montantHT).toFixed(2) + " €";
        plan.margee = Number(plan.marge).toFixed(2) + " €";
    });


    return (
        <>
            <CustomTable
                data={table_planning}
                columns={columns}
                loading={isLoading}
                onRowDoubleClick={onRowDoubleClick}
                handlePointerEnter={handlePointerEnter}
                handlePointerLeave={handlePointerLeave}
                onSelectRows={onSelectRows}
                coloredRow={'hasBon'}
                renderActionCell={(rowData, measuredRef) => (
                    <PlanningTableActionsCell planningId={rowData.id} planningComments={rowData.commentaire_interne}
                                              measuredRef={measuredRef}/>
                )}
                rendersummary={()=> <TableListSummary collection={planning}/>}
            />


            <RichTooltip
                placement="top"
                arrow={true}
                anchorEl={cellHoverAnchorEl}
                content={<PlanningTableInterlocuteurCell rowData={rowData}/>}
            />

            <PlanningInformedDialog
                open={informedDialogOpen}
                onClose={() => setInformedDialogOpen(false)}
                rowData={rowData}
            />
        </>
    );
}

interface Props {
    rowData: Planning | null;
}

const PlanningTableInterlocuteurCell = ({ rowData }: Props) => {
    return (
        <Stack direction="column">
            <Typography variant="body1">{rowData?.commandePar}</Typography>
            <Typography variant="body2">Téléphone: {rowData?.contact?.telephone}</Typography>
            <Typography variant="body2">Email: {rowData?.contact?.email}</Typography>
        </Stack>
    );
};

export default PlanningTable;

