import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUsersBySearch } from '../../../../core/user/selectors';
import { retrieveAllUsers } from '../../../../core/user/use-cases';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { CustomTable } from '../../../common/custom-table';
import { ColumnEntity } from '../../../common/custom-table/Table.entities';
import { User } from '../../../../core/user/entities/user.entity';
import UserTableActionsCell from './UserTableActionsCell';
import { Person, PersonOff } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

function UserTable({ onSelectRows }: any) {
  const [isLoading, setIsLoading] = useState(true);

  const users = useSelector(selectUsersBySearch);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(retrieveAllUsers()).then(() => setIsLoading(false));

    return () => {
      setIsLoading(false);
    };
  }, [dispatch, setIsLoading]);

  const onRowDoubleClick = ({ id }: User) => navigate(`../${id}`, { state: id });

  const columns: ColumnEntity[] = [
    {
      field: 'isActive',
      headerName: '',
      width: 40,
      renderCell: (param) => {
        if (param === true) {
          return (
            <Tooltip title="Utilisateur actif">
              <Person color="primary" />
            </Tooltip>
          );
        }

        if (param === false) {
          return (
            <Tooltip title="Utilisateur inactif">
              <PersonOff color="error" />
            </Tooltip>
          );
        }

        return <></>; // if prior tests are not meet, it's most likely the header, so we return nothing
      },
    },
    { field: 'name', headerName: 'Prénom', width: 310, sortable: true, resizable: true },
    { field: 'lastname', headerName: 'Nom', width: 350, sortable: true, resizable: true },
    { field: 'employeur.name', headerName: 'Employeur', width: 310, sortable: true, resizable: true },
    { field: 'email', headerName: 'Email', width: 375, sortable: true, resizable: true },
    { field: 'telephone', headerName: 'Téléphone', width: 200, sortable: true, resizable: true },
    { field: 'roles', headerName: 'Rôle', width: 150, sortable: true, resizable: true },
  ];

  return (
    <>
      <CustomTable
        data={users}
        columns={columns}
        loading={isLoading}
        hover={false}
        onRowDoubleClick={onRowDoubleClick}
        onSelectRows={onSelectRows}
        renderActionCell={(rowData, measuredRef) => <UserTableActionsCell id={rowData.id} measuredRef={measuredRef} />}
      />
    </>
  );
}

export default UserTable;
