import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectArticlesBySearch } from '../../../../core/article/selectors';
import { retreiveAllArticles } from '../../../../core/article/use-cases';
import { CustomTable } from '../../../common/custom-table';
import { ColumnEntity } from '../../../common/custom-table/Table.entities';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { ArticleEntity } from '../../../../core/article/entities/article.entity';
import { ArticleTableActionsCell } from './';

function ArticleTable({ onSelectRows }: any) {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();
  const articles = useSelector(selectArticlesBySearch);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(retreiveAllArticles()).then(() => setIsLoading(false));

    return () => {
      setIsLoading(true);
    };
  }, [dispatch, setIsLoading]);

  const onRowDoubleClick = ({ id }: ArticleEntity) => navigate(`../${id}`, { state: id });

  const columns: ColumnEntity[] = [
    { field: 'name', headerName: 'Nom', width: 350, sortable: true, resizable: true },
    { field: 'description', headerName: 'Description', width: 420, sortable: true, resizable: true },
    { field: 'fournisseur.name', headerName: 'Fournisseur', width: 350, sortable: true, resizable: true },
    { field: 'prixHT', headerName: 'Prix HT', width: 350, sortable: true, resizable: true },
    { field: 'prixVenteHT', headerName: 'Prix Vente HT', width: 150, sortable: true, resizable: true },
    { field: 'tauxTva', headerName: 'Taux TVA', width: 120, sortable: true, resizable: true },
  ];

  return (
    <>
      <CustomTable
        data={articles}
        columns={columns}
        loading={isLoading}
        hover={false}
        onRowDoubleClick={onRowDoubleClick}
        onSelectRows={onSelectRows}
        renderActionCell={(rowData, measuredRef) => (
          <ArticleTableActionsCell id={rowData.id} measuredRef={measuredRef} />
        )}
      />
    </>
  );
}

export default ArticleTable;
