import { BonEntity } from "../../Domain/Bons/entities";
import { Divider, ListItemText, Stack } from "@mui/material";
import { Planning } from "../../Domain/Planning/entities/planning.entity";
import { useSummary } from "./hooks/useListSummary";

export function TableListSummary({ collection }: { collection: Array<Planning | BonEntity> }) {
    const { totalHT, montantHT, marge } = useSummary(collection);

    return <>
        <Stack direction='row' justifyContent={'end'}>
            <ListItemText primary='Total HT' secondary={totalHT} inset sx={{ pr: 2 }}/>
            <Divider orientation="vertical" flexItem/>
            <ListItemText primary="Montant HT" secondary={montantHT} inset sx={{ pr: 2 }}/>
            <Divider orientation="vertical" flexItem/>
            <ListItemText primary="Marge" secondary={marge} inset sx={{ pr: 2 }}/>
        </Stack>
    </>
}