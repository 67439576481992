import { isValid, parse, getYear, lastDayOfYear, format } from "date-fns";
import * as XLSX from "xlsx";
import { StringDateFR } from "../../../_config/types";

export class AssertionError extends Error {
    constructor(message: string) {
      super(message);
      this.name = 'AssertionError';
    }
  }
  
export function assert(condition = false, message = 'DEFAULT MESSAGE') {
    if (!condition) {
        throw new AssertionError(`Assertion failed: ${message}`);
    }
}

  
export const PERIOD_SEPARATOR = ' - ';

export const getFormatedDefaultPeriodOnFiscalReferenceYear = () => {
    const formatedStartDate = `01/01/${getYear(Date.now())}`
    const formatedEndDate = format(lastDayOfYear(Date.now()), dateFormat)
    return `${formatedStartDate}${PERIOD_SEPARATOR}${formatedEndDate}`
}

export const exportToXLSX = (data: any[], filename: string, heading: string[]) => {
    const FILE_EXTENSION = ".xlsx";

    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [heading]);
    // Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws);
    // Download file
    XLSX.writeFile(wb, `${filename}.${FILE_EXTENSION}`);
};

export const validateDates = (selectedDates: string) => {
    if ( selectedDates === '' ) {
        return { fromDate: '', toDate: '' };
    }

    const [fromDate, toDate] = selectedDates.split(PERIOD_SEPARATOR);
    if ( isValidateFrDateString(fromDate) && isValidateFrDateString(toDate) ) return {
        fromDate: fromDate,
        toDate: toDate
    }
    throw new Error('impossible de lire la date')
    // return null;
}

export const isValidateFrDateString = (date: string): boolean => {
    const DATE_FORMAT = "dd/MM/yyyy";
    return isValid(parse(date, DATE_FORMAT, new Date()));
}

/**
 * remove falsy values before sending to api
 */
export const removeEmpty = <O extends Object>(obj: O): Partial<O> => {
    return Object.fromEntries(
        Object.entries(obj)
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
            .filter(([_, v]) => v !== null && v !== undefined && v !== "" && v !== false)
            .filter(([_, v]) => !Array.isArray(v) || v.length > 0)
            .filter(([_, v]) => v !== 0)
    );
}

export const dateFormat = "dd/MM/yyyy";

export const roles = [
    { label: 'Admin', value: 'admin' },
    { label: 'Chauffeur', value: 'chauffeur' },
    { label: 'Client', value: 'client' },
    { label: 'Fournisseur', value: 'fournisseur' },
];

export const unites = [
    { label: 'Journée', value: 'journee' },
    { label: 'Heure', value: 'heure' },
    { label: 'Forfait', value: 'forfait' },
    { label: 'm³', value: 'm3' },
    { label: 'Tour', value: 'tour' },
    { label: 'Nuit', value: 'nuit' },
    { label: 'Heure Nuit', value: 'heure_nuit' },
    { label: 'Heure Supp.', value: 'heure_supp' },
    { label: 'Litre', value: 'litre' },
    { label: 'Tonne', value: 'tonne' },
]

export const factureStatus = [
    { label: 'Nouvelles', value: 'CREATED' },
    { label: 'Editées', value: 'EDITED' },
    { label: 'Payées', value: 'PAID' },
    { label: 'Annulées', value: 'CANCELLED' },
    { label: 'Archive', value: 'ARCHIVE' }
]

export function toDateStringFr(aDateString: string): StringDateFR {
    const frRegex = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/
    if ( frRegex.test(aDateString) ) return aDateString;

    const usRegex = /[0-9]{4}\/[0-9]{2}\/[0-9]{2}/
    if ( usRegex.test(aDateString) ) return aDateString.split('/').reverse().join('/');

    const aDateObject = new Date(aDateString);
    if ( aDateObject.toString() !== "Invalid Date" ) {
        return `${padNumber(aDateObject.getDate())}/${padNumber(aDateObject.getMonth() + 1)}/${aDateObject.getFullYear()}`
    }

    throw new Error('format de date invalide. Impossible de parser la valeur donnée en date')
}

function padNumber(value: number) {
    return value < 10 ? `0${value}` : value;
}