import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../App/core/notification/notification.slice';
import { selectAllPaymentTypes } from '../../../../App/core/payment-type/selectors';
import { retrieveAllPaymentType } from '../../../../App/core/payment-type/use-cases';
import { validatePayment } from '../../../Domain/Application/use-cases/validate-payment';
import { Facture } from '../../../Domain/Model';
import { selectAllribTypes } from '../../../../App/core/rib-type/selectors';
import { updateOneFacture } from '../../../Domain/Application/use-cases/update-one-bon';

interface Props {
  open: boolean;
  facture: Facture | undefined;
  onCloseDialog(): void;
}

export const FactureValidatePaymentDialog = ({ open, facture, onCloseDialog }: Props) => {
  const [montant, setMontant] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [soldeChecked, setSoldeChecked] = useState(false);
  const [bank, setBank] = useState('');

  
  const dispatch = useAppDispatch();
  const paymentTypes = useSelector(selectAllPaymentTypes);
  const ribTypes = useSelector(selectAllribTypes);

  useEffect(() => {
    dispatch(retrieveAllPaymentType());
  }, [dispatch]);

  const onConfirmPayment = async (buttonId?:string) => {
    if(buttonId === "lettrage") {
      const reslettrage: any = facture?.id
      ? await dispatch(
          updateOneFacture({
            id: facture.id,
            lettrage: "new",
          })
        )
      : null;
    }
    const type = soldeChecked ? 'SOLDE' : paymentType;
    const res: any = facture ? await dispatch(validatePayment({ id: facture.id, montant, paymentType: type, bank: bank })) : null;

    if (!res?.error) {
      dispatch(setSuccess(`Le règlement a bien été validé`));
      onCloseDialog();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Valider le règlement</DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={2} p={2} minWidth={300}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Montant"
            value={montant}
            onChange={(event) => setMontant(event.target.value)}
            sx={{ mb: 1 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <FormControl>
            <InputLabel id="paymentSelect">Type de paiement</InputLabel>
            <Select
              labelId="paymentSelect"
              required
              fullWidth
              variant="outlined"
              label="Type de paiement"
              value={paymentType}
              onChange={(event) => setPaymentType(event.target.value)}
            >
              {paymentTypes.map((p) => (
                <MenuItem key={p.code} value={p.code}>
                  {p.wording}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <FormControl>
            <InputLabel id="bankSelect">Banque</InputLabel>
            <Select
              labelId="bankSelect"
              required
              fullWidth
              variant="outlined"
              label="Banque"
              value={bank}
              onChange={(event) => setBank(event.target.value)}
            >
              {ribTypes.map((r) => (
                <MenuItem key={r._id} value={r._id}>
                  {r.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="body2" color="#666767">
            Solde règlement client: {facture?.client.solde_reglement_client} €
          </Typography>
          

          {!!facture?.client.solde_reglement_client ? (
            <FormControlLabel
              control={<Checkbox checked={soldeChecked} onChange={(e) => setSoldeChecked(e.target.checked)} />}
              label="Utiliser solde"
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Fermer</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onConfirmPayment('lettrage')}
          disabled={!(!!montant && (!!paymentType || soldeChecked))}
        >
          Valider et lettrer
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onConfirmPayment()}
          disabled={!(!!montant && (!!paymentType || soldeChecked))}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};
