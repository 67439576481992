import React, { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import { EditRounded as EditIcon, AttachFileRounded as AttachIcon, Gesture } from '@mui/icons-material';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import ConfirmationDialog from '../../../../App/app/common/modals/components/ConfirmationDialog';
import { setSuccess } from '../../../../App/core/notification/notification.slice';

import { CommentaireTableActionCell, BonAttachmentsDialog, BonSignatureFormDialog } from '../common';
import { archiveBons } from '../../../Domain/Bons/use-cases';

interface Props {
    id: EntityId;
    comments: string;
    measuredRef: any;
}

const BonTableActionsCell = ({ id, comments, measuredRef }: Props) => {
    const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
    const [isAttachmentsDialogOpen, setIsAttachmentsDialogOpen] = useState(false);
    const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);
    const [isConfirmArchiveButtonLoading, setIsConfirmArchiveButtonLoading] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    /* Edit Action */
    const onClickEdit = () => navigate(`../${id}`, { state: id });

    /* Archive Action */
    const handleArchiveDialog = () => setIsArchiveDialogOpen(!isArchiveDialogOpen);

    const onConfirmArchive = async () => {
        setIsConfirmArchiveButtonLoading(true);

        const res: any = await dispatch(archiveBons([id]));

        if ( !res.error ) {
            dispatch(setSuccess(`Les données ont bien été archivées`));
            setIsArchiveDialogOpen(false);
        }

        setIsConfirmArchiveButtonLoading(false);
    };

    /* View Attachment Action */
    const handleAttachmentsDialog = () => setIsAttachmentsDialogOpen(!isAttachmentsDialogOpen);

    /* Manual Signature Action */
    const handleSignatureDialog = () => setIsSignatureDialogOpen(!isSignatureDialogOpen);

    return (
        <>
            {/* Buttons */}
            <Stack direction="row">
                {/* Use ref here to dynamically get the size of the div and use the same in column */}
                <Box ref={measuredRef}>


                    <Tooltip title="Editer">
                        <IconButton aria-label="editer" color="primary" size="small" onClick={onClickEdit}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Signature manuelle">
                        <IconButton size="small" color="success" onClick={handleSignatureDialog}>
                            <Gesture/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Pièces-jointes">
                        <IconButton aria-label="consulter-piece-jointe" size="small" onClick={handleAttachmentsDialog}>
                            <AttachIcon/>
                        </IconButton>
                    </Tooltip>

                    <CommentaireTableActionCell comment={comments}/>

                </Box>
            </Stack>

            {/* Dialogs */}
            <ConfirmationDialog
                contentText="Etes-vous sûr de vouloir archiver cette ligne ?"
                title="Supprimer"
                open={isArchiveDialogOpen}
                isButtonLoading={isConfirmArchiveButtonLoading}
                onClose={handleArchiveDialog}
                onConfirm={onConfirmArchive}
            />
            <BonAttachmentsDialog id={id} open={isAttachmentsDialogOpen} onClose={handleAttachmentsDialog}/>
            <BonSignatureFormDialog bonId={id} open={isSignatureDialogOpen} onClose={handleSignatureDialog}/>
        </>
    );
};

export default BonTableActionsCell;
