import React, { useState } from 'react';

import {
    Popover,
    Stack,
    TextField,
    Button,
    Typography,
    Autocomplete,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import { RestartAlt } from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';

import { selectAllChantiersLabelsAndIds } from '../../../../../App/core/chantier/selectors';
import { selectAllInterlocuteursLabelsAndIds } from '../../../../../App/core/interlocuteur/selectors';
import { selectors as articleSelectors } from '../../../../../App/core/article';
import { selectAllLabelsAndIds } from '../../../../../App/core/entreprise/selectors';

import { updateFilters } from '../../../../Domain/Application/facture.slice';
import { FactureFilters } from '../../../../Domain/Model/entities/filter-facture.entity';
import { selectFilters } from '../../../../Domain/Application/selectors';
import { factureEntreprise, factureStatus } from "./FactureStatusLabelToValue";

interface Props {
    open: boolean;
    anchorEl: Element | null;
    type: "client" | "fournisseur" | "";
    onCloseSearchPopover(): void;
}

function FactureSearchPopover({ open, anchorEl, onCloseSearchPopover, type }: Props) {
    const savedFilters:any = useSelector(selectFilters);
    const emptyFilters = {
        type: '',
        fournisseur: '',
        chantier: '',
        numeroFacture: '',
        dateDeFacturation: '',
        status: '',
        client: '',
        reglement: '',
        totalTva: 0,
        totalTTC: '',
        totalFraisAutoroute: 0,
    };
    const defaultValues = savedFilters ? savedFilters : emptyFilters;

    const [filters, setFilters] = useState<FactureFilters>(defaultValues);

    const entreprises = useSelector(selectAllLabelsAndIds);
    const chantiers = useSelector(selectAllChantiersLabelsAndIds);
    const interlocuteurs = useSelector(selectAllInterlocuteursLabelsAndIds);
    const articles = useSelector(articleSelectors.selectAllArticlesLabelsAndIds);
    const dispatch = useAppDispatch();

    const isOptionEqualToValue = (option: any, value: any) => option.id === value.id || value.id === ''; // if id is empty returns true to remove warnings in console

    const getFilterValue = (options: { id: string; label: string }[], property: keyof FactureFilters) => {
        return options.find((option) => option.id === filters[property] || option.label === filters[property]) ?? { id: '', label: '' };
    };

    const handleInputChange = (params: { property: string; value: any }) => {
        const { property, value } = params;
        if ( value ) {
            if(property === 'chantier' || property === 'fournisseur') { setFilters({ ...filters, [property]: value.label }); }
            else { setFilters({ ...filters, [property]: value.id }); }
        } else {
            setFilters({ ...filters, [property]: '' });
        }
    };

    const onClickSaveFilters = () => {
        onCloseSearchPopover();
        dispatch(updateFilters(filters));
    };

    const onResetForm = () => {
        setFilters(emptyFilters);
        dispatch(updateFilters(emptyFilters));
    };
    
    return (
        <Popover open={open} anchorEl={anchorEl}>
            <Stack direction="column" gap={2} width={400} p={3}>
                <Typography variant="h5">Filtres</Typography>


                {type === "" && <FormControl>
                <TextField
                    fullWidth
                    label="Numéro d'avoir"
                    placeholder="20YYMMXXXX"
                    id="numeroFacture"
                    value={filters.numeroFacture}
                    onChange={(event) => setFilters({ ...filters, numeroFacture: event.target.value })}
                    variant="outlined"
                    name="numeroFacture"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                />

                </FormControl>}


                {/* Numéro de facture */}
                <FormControl>
                <TextField
                    fullWidth
                    label="Numéro de facture"
                    placeholder="20YYMMXXXX"
                    id="numeroFacture"
                    value={filters.numeroFacture}
                    onChange={(event) => setFilters({ ...filters, numeroFacture: event.target.value })}
                    variant="outlined"
                    name="numeroFacture"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                    type="text"
                    InputLabelProps={{ shrink: true }}
                />

                </FormControl>


                                {/* Montant total TTC*/}
                                <FormControl>
                <TextField
                    fullWidth
                    label="Montant total TTC"
                    placeholder="123.45"
                    id="totalTTC"
                    value={filters.totalTTC}
                    onChange={(event) => setFilters({ ...filters, totalTTC: event.target.value })}
                    variant="outlined"
                    name="totalTTC"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                />

                </FormControl>


                {/* TypeEntreprise 
                <FormControl>
                    <InputLabel sx={{ ml: -2 }} id="select-type-label">
                        Type
                    </InputLabel>
                    <Select
                        labelId="select-type-label"
                        value={filters.type}
                        variant="standard"
                        label="Type"
                        onChange={(event) => setFilters({ ...filters, type: event.target.value })}
                    >
                        <MenuItem value="">
                            <em>Tout</em>
                        </MenuItem>
                        {factureEntreprise.map((s) => (
                            <MenuItem key={s.value} value={s.value}>
                                {s.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>*/}


                {/* Client */}
                
                {(type === "client" || type === "") && (
                <Autocomplete
                    id="client"
                    options={entreprises}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(entreprises, 'client')}
                    onChange={(e, value) => handleInputChange({ property: 'client', value })}
                    renderInput={(params) => <TextField {...params} label="Client" variant="standard"/>}
                /> 
                )}
                {(type === "client" || type === "") && (
                <Autocomplete
                    id="fournisseur"
                    options={entreprises}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(entreprises, 'fournisseur')}
                    onChange={(e, value) => handleInputChange({ property: 'fournisseur', value })}
                    renderInput={(params) => <TextField {...params} label="Fournisseur" variant="standard"/>}
                /> 
                )}





                {/* Facture fournisseur : bdc  */}
                {type === "fournisseur" && <Autocomplete
                    id="client"
                    options={entreprises}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(entreprises, 'client')}
                    onChange={(e, value) => handleInputChange({ property: 'client', value })}
                    renderInput={(params) => <TextField {...params} label="Fournisseur" variant="standard"/>}
                /> }
                {/* Chantier */}
                <Autocomplete
                    options={chantiers}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(chantiers, 'chantier')}
                    onChange={(e, value) => handleInputChange({ property: 'chantier', value })}
                    renderInput={(params) => <TextField {...params} label="Chantier" variant="standard"/>}
                />
                {/* Fournisseur 
                <Autocomplete
                    options={entreprises}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={getFilterValue(entreprises, 'fournisseur')}
                    onChange={(e, value) => handleInputChange({ property: 'fournisseur', value })}
                    renderInput={(params) => <TextField {...params} label="Fournisseur" variant="standard"/>}
                />*/}

                {/* Status */}
                <FormControl>
                    <InputLabel sx={{ ml: -2 }} id="select-status-label">
                        Statut
                    </InputLabel>
                    <Select
                        labelId="select-status-label"
                        value={filters.status}
                        variant="standard"
                        label="Statut"
                        onChange={(event) => setFilters({ ...filters, status: event.target.value })}
                    >
                        <MenuItem value="">
                            <em>Tout</em>
                        </MenuItem>
                        {factureStatus.map((s) => (
                            <MenuItem key={s.value} value={s.value}>
                                {s.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>




                <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Tooltip title="Réinitialiser">
                        <Button variant="outlined" color="error" onClick={onResetForm}>
                            <RestartAlt/>
                        </Button>
                    </Tooltip>
                    <Stack direction="row" justifyContent="flex-end" gap={2}>
                        <Button onClick={onCloseSearchPopover}>Fermer</Button>
                        <Button color="primary" variant="contained" onClick={onClickSaveFilters}>
                            Enregistrer
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Popover>
    );
}

export default FactureSearchPopover;
