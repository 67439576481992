/// <reference path="../Model/Bon.ts"/>

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Avoir, Facture } from '../Model';
import {
    addBonDeCommande,
    addOneAvoir,
    addOneFacture, confirmBonDeCommande,
    emitOneFacture,
    deleteOneFacture,
    retrieveAllFactures,
    retrieveOneAvoir,
    validatePayment
} from './use-cases';
import { relancerFacture } from './use-cases/relancer-facture';
import { retrieveAllRelances } from './use-cases/retrieve-all-relances';
import { updateOneFacture } from "./use-cases/update-one-bon";
import { Bon } from "../Model/Bon"
import { findPendingBonsDeCommande } from "./use-cases/find-all-pending-bons-de-commande";
import { getFormatedDefaultPeriodOnFiscalReferenceYear } from '../../../App/app/common/_helpers';

export const factureEntityAdapter = createEntityAdapter<Facture>({
    selectId: (facture) => facture.id
});

export const avoirSlice = createSlice({
        name: 'avoirs',
        initialState: factureEntityAdapter.getInitialState({
            filters: null,
            dates: getFormatedDefaultPeriodOnFiscalReferenceYear(),
            avoir: [] as Avoir[],
            relance: [] as any[],
            status: '',
            bonsDeCommandes: [] as Bon[]
        }),
        reducers: {
            updateFilters: (state, action) => {
                state.filters = action.payload;
            },
            updateDate: (state, action) => {
                state.dates = action.payload;
            },
            updateStatus: (state, action) => {
                state.status = action.payload;
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(retrieveAllFactures.fulfilled, (state, action) => {
                    factureEntityAdapter.setAll(state, action.payload);
                })
                .addCase(findPendingBonsDeCommande.fulfilled, (state, action) => {
                    state.bonsDeCommandes = action.payload.bonsDeCommande.slice()
                })
                .addCase(addOneFacture.fulfilled, (state, action) => {
                    factureEntityAdapter.addOne(state, action.payload);
                })
                .addCase(addBonDeCommande.fulfilled, (state, action) => {
                    factureEntityAdapter.addOne(state, action.payload.createdBonDeCommande)
                })
                .addCase(confirmBonDeCommande.fulfilled, (state, action) => {
                    factureEntityAdapter.updateOne(state,
                        {
                            id: action.payload.confirmedBonDeCommande.id,
                            changes: {
                                numeroFacture: action.payload.confirmedBonDeCommande.numeroFacture,
                                status: "PAID"
                            }
                        })
                })
                .addCase(updateOneFacture.fulfilled, (state, action) => {
                    factureEntityAdapter.upsertOne(state, action.payload);
                })
                .addCase(addOneAvoir.fulfilled, (state, action) => {
                    state.avoir = [...state.avoir, action.payload];
                })
                .addCase(retrieveOneAvoir.fulfilled, (state, action) => {
                    state.avoir = action.payload;
                })
                .addCase(retrieveAllRelances.fulfilled, (state, action) => {
                    state.relance = action.payload;
                })
                .addCase(relancerFacture.fulfilled, (state, action) => {
                    state.relance = [...state.relance, action.payload]
                })
                .addCase(emitOneFacture.fulfilled, (state: any, { payload }: any) => {
                    factureEntityAdapter.updateOne(state, { id: payload.id, changes: { status: payload.status } });
                })
                .addCase(deleteOneFacture.fulfilled, (state: any, payload: any) => {
                    factureEntityAdapter.removeOne(state, payload.id );
                })
                .addCase(validatePayment.fulfilled, (state, { payload }) => {
                    factureEntityAdapter.updateOne(state, {
                        id: payload.id,
                        changes: {
                            status: payload.status,
                            reglement: { montant: payload.reglement.montant }
                        }
                    });
                })
        }
    })
;

export const { updateFilters, updateDate, updateStatus } = avoirSlice.actions;
export default avoirSlice.reducer;
