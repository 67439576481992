import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { object, setLocale, string, boolean } from 'yup';

import { TextField, Grid, Stack, Button, Box, Tab, InputLabel, Select, MenuItem, Checkbox, SelectChangeEvent, FormControlLabel, FormControl } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Business, Email, Numbers, Phone, Public } from '@mui/icons-material';
import { EntrepriseEntity } from '../../../../core/entreprise/entities';
import { yupFr } from '../../../common/i18n';
import { useSelector } from 'react-redux';
import { selectAllribTypes } from '../../../../core/rib-type/selectors';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { retrieveAllribType } from '../../../../core/rib-type/use-cases';

interface InitialFormValues {
  name: string;
  rue: string;
  cp: string;
  ville: string;
  email: string;
  telephone: string;
  code_fournisseur: string;
  code_client: string;
  fin_du_mois: boolean;
  rib_mttp: string;
  isPaiementInEndOfMonth?: boolean
}

interface Props {
  initialValues: InitialFormValues;
  onSubmit(values: any): void;
  entreprise?: EntrepriseEntity;
  onRibSelection(e: SelectChangeEvent): void;
}

function EntrepriseForm({ initialValues, onSubmit, entreprise, onRibSelection }: Props) {
  setLocale(yupFr);
  const schema = object().shape({
    name: string().required(),
    rue: string().notRequired(),
    cp: string().notRequired(),
    ville: string().notRequired(),
    email: string().notRequired(),
    telephone: string().notRequired(),
    code_fournisseur: string().notRequired(),
    code_client: string().notRequired(),
    fin_du_mois: string().notRequired(),
    rib_mttp: string().notRequired(),
    isPaiementInEndOfMonth: boolean().notRequired()
  });
 
  const dispatch = useAppDispatch();
  const ribTypes = useSelector(selectAllribTypes);

    useEffect(() => {
        dispatch(retrieveAllribType())

        return () => {

        };
    }, [dispatch]);


  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}  validationSchema={schema}>
      {(props) => {
        const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } = props;
        return (
          <>

            {entreprise && (
                    <>
                    <TabContext value={'default'}>   
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList>
                              <Tab label="Données générales" value="default" />
                              <Tab label="Gestion des chantiers"  component={Link} to={`../${entreprise.id}/chantiers`} />
                              <Tab label="Gestion des interlocuteurs" component={Link} to={`../${entreprise.id}/interlocuteurs`} />
                              <Tab label="Gestion des règlements" component={Link} to={`../${entreprise.id}/reglements`} />
                              <Tab label="Gestion des adresses de facturation"  component={Link} to={`../${entreprise.id}/facturation/adresse`} />
                          </TabList>
                      </Box>
                    </TabContext>   
              
                    </>
                  )}
            <Form onSubmit={handleSubmit} style={{ display: 'flex' }}>
              <Grid container direction="row" spacing={4} p={4} width={{ xs: '100%', md: '75%', xl: '60%' }}>
                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Business sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Nom"
                      error={!!errors.name && !!touched.name}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      required
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Numbers sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Code fournisseur"
                      name="code_fournisseur"
                      value={values.code_fournisseur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Numbers sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Code client"
                      name="code_client"
                      value={values.code_client}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
                


                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Public sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Rue"
                      name="rue"
                      value={values?.rue}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Stack direction="row" gap={5}>
                    <span />
                    <TextField
                      fullWidth
                      label="Code Postal"
                      name="cp"
                      value={values.cp}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={9} ml={{ xs: 5, md: 0 }}>
                  <TextField
                    fullWidth
                    label="Ville"
                    name="ville"
                    value={values.ville}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Email sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <Phone sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Téléphone"
                      name="telephone"
                      value={values.telephone}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
 
                <Grid item xs={12} md={5}>
                  <Stack direction="row" gap={5}>
                    <span />
                    <FormControl fullWidth>
              

                      
                      <InputLabel id="rib_mttp">Rib par défaut</InputLabel>
                      <Select 
                        label="RIB" 
                        labelId="rib_mttp" 
                        name="rib_mttp"
                        defaultValue={values.rib_mttp}
                        onChange={handleChange}>
                          {ribTypes.map(r =>
                              <MenuItem key={r._id} value={r._id}>
                                  {r.name}
                              </MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>


                <Grid item xs={12} md={5}>
                  <FormControlLabel 
                    label="Paiement fin du mois"
                    name="isPaiementInEndOfMonth"
                    onChange={handleChange}
                    control={(<Checkbox
                      checked={values.isPaiementInEndOfMonth}
                    />)}/>
                </Grid>
            
        

                <Grid item display="flex" justifyContent="flex-end" sx={{ my: 2 }} xs={12} gap={3}>
                  

                  <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
                    Enregistrer
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default EntrepriseForm;
