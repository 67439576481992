import { planningEntityAdapter } from './planning.slice';
import { RootState } from '../../../_config/redux';
import { EntityId } from '@reduxjs/toolkit';

const planningSelector = planningEntityAdapter.getSelectors<RootState>((state) => state.planning);

export const selectAllPlanning = (state: RootState) => {
    const planning = planningSelector.selectAll(state);
    return planning
        .sort((a, b) => a.article.localeCompare(b.article))
        .sort((a, b) => a.chantier.localeCompare(b.chantier))
        .sort((a, b) => a.client.localeCompare(b.client))
};

export const selectPlanningById = (id: EntityId) => (state: RootState) => planningSelector.selectById(state, id);

export const selectPlanningByIds = (id: EntityId[]) => (state: RootState) => planningSelector.selectAll(state).filter(planning => id.includes(planning.id));

export const selectPlanningBySearch = (state: RootState) => {
    const propertiesToFilter = ['client', 'chantier', 'commandePar', 'article', 'fournisseur'];
    const plannings = selectAllPlanning(state);
    const { search } = state.planning;

    return plannings.filter((planning: any) => {
        return Object.keys(planning).some((property) => {
            return propertiesToFilter.includes(property)
                ? planning?.[property]?.toLowerCase().includes(search.toLowerCase())
                : false;
        });
    });
};
