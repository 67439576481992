import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';

export const retrieveOneAvoir = createAsyncThunk(
  '[FACTURE] retrieveOneAvoir',
  async (factureId: EntityId, thunk: any) => {
    try {
      return await thunk.extra.factureQuery.retrieveOneAvoir(factureId);
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
    }
  }
)