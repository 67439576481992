/**
 * Redux-store
 */
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from '../../App/core/auth';
import userReducer from '../../App/core/user';
import entrepriseReducer from '../../App/core/entreprise';
import interlocuteurReducer from '../../App/core/interlocuteur';
import articleReducer from '../../App/core/article';
import { chantierSlice } from '../../App/core/chantier/chantier.slice';
import notificationReducer from '../../App/core/notification';
import paymentTypeReducer from '../../App/core/payment-type/payment-type.slice';
import ribTypeReducer from '../../App/core/rib-type/rib-type.slice';
import affacturageTypeReducer from '../../App/core/affacturage-type/affacturage-type.slice';
import categorieProduitReducer from '../../App/core/categorie-produit/categorie-produit.slice';
import serveurTypeReducer from '../../App/core/serveur-type/serveur-type.slice';
import factureReducer from '../../Facturation/Domain/Application/facture.slice';
import bdcReducer from '../../Facturation/Domain/Application/bdc.slice';
import avoirReducer from '../../Facturation/Domain/Application/avoir.slice';
import bonReducer from '../../Location/Domain/Bons';
import planningReducer from '../../Location/Domain/Planning';

import {
    EntrepriseApi,
    ChantierApi,
    UserApi,
    PlanningApi,
    AuthApi,
    ArticleApi,
    InterlocuteurApi,
    BonApi,
    FactureApi,
    PaymentTypeApi,
    ribTypeApi,
    CategorieProduitApi,
    affacturageTypeApi,
    serveurTypeApi,
    restartApi
} from '../../App/infra/api';
import { BonDeCommandeHttpRepository } from "../../Facturation/Data/source/http/BonDeCommande.httpRepository";

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    entreprises: entrepriseReducer,
    planning: planningReducer,
    chantiers: chantierSlice.reducer,
    interlocuteurs: interlocuteurReducer,
    articles: articleReducer,
    bons: bonReducer,
    notifications: notificationReducer,
    factures: factureReducer,
    bdcs : bdcReducer,
    avoirs : avoirReducer,
    paymentType: paymentTypeReducer,
    ribType: ribTypeReducer,
    categorieProduit: categorieProduitReducer,
    affacturageType: affacturageTypeReducer,
    serveurType: serveurTypeReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    authService: AuthApi,
                    userQuery: UserApi,
                    planningService: PlanningApi,
                    entrepriseQuery: EntrepriseApi,
                    chantierQuery: ChantierApi,
                    articleQuery: ArticleApi,
                    interlocuteurQuery: InterlocuteurApi,
                    bonQuery: BonApi,
                    factureQuery: FactureApi,
                    bonDeCommandeService: new BonDeCommandeHttpRepository(),
                    paymentTypeQuery: PaymentTypeApi,
                    ribTypeQuery: ribTypeApi,
                    categorieProduitQuery: CategorieProduitApi,
                    affacturageTypeQuery : affacturageTypeApi,
                    serveurTypeQuery : serveurTypeApi,
                    restartQuery: restartApi
                }
            }
        }),

    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
