import { EntityId } from "@reduxjs/toolkit";
import { userEntityAdapter } from ".";
import { RootState } from "../../../_config/redux";

const userSelector = userEntityAdapter.getSelectors<RootState>((state) => state.user)

export const selectAllUsers = (state: RootState) => {
    const users = userSelector.selectAll(state);
    return users;
}

export const selectById = (state: RootState, action: { payload: { id: string } }) => userSelector.selectById(state, action.payload.id)

export const selectUserById = (id: EntityId) => (state: RootState) => userSelector.selectById(state, id);

export const selectUserErrors = (state: RootState) => state.user.errors;

export const selectUsersBySearch = (state: RootState) => {
    const propertiesToFilter = ['name', 'lastname', 'email', 'entreprise'];
    const { search, filter } = state.user;
    let users = selectAllUsers(state);

    if ( !!filter.status ) {
        users = users.filter((user) => user.isActive === (filter.status === 'active'));
    }

    if ( !!filter.role ) {
        users = users.filter((user) => filter.role === user.roles)
    }

    return users.filter((user: any) => {
        return Object.keys(user).some((property) => {
            return propertiesToFilter.includes(property)
                ? user?.[property]?.toLowerCase().includes(search.toLowerCase())
                : false;
        });
    });
};

export const selectChauffeursByCompany = (companyId: EntityId) => (state: RootState) => {
    const selectAllUsers = userSelector.selectAll(state);
    return selectAllUsers
        .filter((user) => user.employeur?.id === companyId && user.roles === 'chauffeur')
        .map((user) => ({ id: user.id, label: `${user.name} ${user.lastname.toUpperCase()}` }));
}

export const selectChauffeursLabelsAndIds = (state: RootState) => {
    const selectAllUsers = userSelector.selectAll(state);
    return selectAllUsers
        .filter((user) => user.roles === 'chauffeur')
        .map((user) => ({ id: user.id, label: `${user.name} ${user.lastname.toUpperCase()}` }));
}