import { Divider, ListItemText, Stack } from "@mui/material";
import { Facture } from "../../Domain/Model/entities/facture.entity";
import { useSummary } from "../../../Location/Presentation/components/hooks/useFactureListSummary";

export function TableListSummary({ collection }: { collection: Array<Facture> }) {
    const { totalHT, totalTVA, totalTTC } = useSummary(collection);

    return <>
        <Stack direction='row' justifyContent={'end'}>
            <ListItemText primary='Total HT' secondary={totalHT} inset sx={{ pr: 2 }}/>
            <Divider orientation="vertical" flexItem/>
            <ListItemText primary="Total TVA" secondary={totalTVA} inset sx={{ pr: 2 }}/>
            <Divider orientation="vertical" flexItem/>
            <ListItemText primary="Total TTC" secondary={totalTTC} inset sx={{ pr: 2 }}/>
        </Stack>
    </>
}