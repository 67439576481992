import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";
import { Box } from "@mui/material";
import { CustomTable } from "../../../../App/app/common/custom-table";
import { ColumnEntity } from "../../../../App/app/common/custom-table/Table.entities";
import {
  emitOneFacture,
  retrieveAllFactures,
  deleteOneFacture,
  confirmBonDeCommande as confirmBonDeCommandeUsecase,
  retrieveOneAvoir,
} from "../../../Domain/Application/use-cases";
import { RootState } from "../../../../_config/redux";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { Facture } from "../../../Domain/Model";
import { selectAllFactures, selectAvoir } from "../../../Domain/Application/selectors";
import { validateDates } from "../../../../App/app/common/_helpers";
import { setSuccess } from "../../../../App/core/notification/notification.slice";
import { FactureTableStatusCell, Status } from "../facture/facture-list/FactureTableStatusCell";
import EmitFactureDialog from "../EmitFactureDialog";
import DeleteFactureDialog from "../DeleteFactureDialog";
import { IconByEntrepriseType } from "../IconByEntrepriseType";
import { TableListSummary } from "../TableListSummary";
import AvoirTableActionsCell from "./AvoirTableActionsCell";

interface Props {
  entrepriseType: "client" | "fournisseur" | "";
  onSelectRows(ids: EntityId[]): void;
  refreshBons(): void;
}

function AvoirTable({ onSelectRows, refreshBons, entrepriseType }: Props) {

  const dispatch = useAppDispatch();
  let factures = useSelector(selectAllFactures);
  const avoirs = useSelector(selectAvoir);
  //console.log(avoirs);
  const selectedDates = useSelector((state: RootState) => state.factures.dates);
  const filters: any = useSelector((state: RootState) => state.factures.filters);

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<Facture>();

  const [dialogsState, setDialogsState] = useState({
    edit: false,
    validate: false,
    avoir: false,
    relance: false,
    emit: false,
    bonDeCommandeValidation: false,
    delete: false,
  });

  const loadFactures = useCallback(
    function () {
      try {
        const { fromDate, toDate } = validateDates(selectedDates);
        dispatch(retrieveAllFactures({ filters, from: fromDate, to: toDate }))
          .unwrap()
          .then(() => {
            setLoading(false);
          });
      } catch (err) {
        console.warn("Erreur lors de la récupération des factures", err);
      }
    },
    [dispatch, selectedDates, filters]
  );

  useEffect(() => {
    loadFactures();
    return () => {
      setLoading(false);
    };
  }, [loadFactures]);

  useEffect(() => {
    const fetchAvoir = async () => {
        await dispatch(retrieveOneAvoir("all"));
    };
    if ( Boolean(factures) ) fetchAvoir();
}, [dispatch, factures]);

  const handleDialogs = (
    buttonId: keyof typeof dialogsState,
    rowData?: Facture
  ) => {
    setLoading(true);
    setRowData(rowData);
    const currentState = dialogsState[buttonId];
    const newState = !currentState;
    setDialogsState({ ...dialogsState, [buttonId]: newState });

    if (newState) {
      
      refreshBons();
      loadFactures();
    }
    setLoading(false);
  };

  // Emettre
  const onConfirmEmit = async (aReveiverEmail: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          emitOneFacture({
            id: rowData.id,
            anEmail: aReveiverEmail,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`La facture a bien été émise`));
      handleDialogs("emit");
    }
  };

  //Supprimer
  const onConfirmDelete = async (data: string) => {
    const res: any = rowData?.id
      ? await dispatch(
          deleteOneFacture({
            id: rowData.id,
          })
        )
      : null;

    if (!res?.error) {
      dispatch(setSuccess(`La facture a bien été supprimée`));
      loadFactures();
      handleDialogs("delete");
    }
  };

  const columns: ColumnEntity[] = [
    {
      field: "status",
      headerName: "",
      width: 40,
      renderCell: (param) =>
        Object.values(Status).includes(param as Status) ? (
          <FactureTableStatusCell param={param} />
        ) : (
          param
        ),
    },
    {
      field: "numero",
      headerName: "N°",
      width: 110,
      sortable: true,
      resizable: true,
    },
    {
      field: "numeroFacture",
      headerName: "Facture",
      width: 110,
      sortable: true,
      resizable: true,
    },
    {
      field: "type",
      headerName: "",
      sortable: true,
      width: 40,
      renderCell(params: "client" | "fournisseur"): React.ReactChild {
        if (Boolean(params))
          return <IconByEntrepriseType entrepriseType={params} />;
        return <></>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      sortable: true,
      resizable: true,
    },
    {
      field: "client.name",
      headerName: "Client",
      width: 320,
      sortable: true,
      flexGrow: 1,
    },  
    {
      field: "montantFacture",
      headerName: "Montant Facture TTC",
      width: 200,
      sortable: true,
      resizable: true,
    },
    {
      field: "montantAvoir",
      headerName: "Montant Avoir TTC",
      width: 200,
      sortable: true,
      resizable: true,
    },  
    {
      field: "diff",
      headerName: "Différentiel",
      width: 150,
      sortable: true,
      resizable: true,
    },  
    {
      field: "used_at",
      headerName: "Consommé le",
      width: 130,
      sortable: true,
      resizable: true,
    },
    {
      field: "parFacture",
      headerName: "Par Facture N°",
      width: 130,
      sortable: true,
      resizable: true,
    },
    {
      field: "dataconso",
      headerName: "N. Encaissement",
      width: 130,
      sortable: true,
      resizable: true,
    },
  ];
  
  if(filters && filters.totalTTC && Number(filters.totalTTC) !== 0 && filters.totalTTC !== '') {
    factures = factures.filter((facture) => facture.totalTTC === Number(filters.totalTTC));
  }

  let table_avoirs = JSON.parse(JSON.stringify(avoirs));

  table_avoirs.forEach((avoir:any) => {
    if(avoir.net_a_deduire.value) { avoir.montantAvoir = Number(avoir.net_a_deduire.value).toFixed(2) + " €"; }
    avoir.date = new Date(avoir.created_at).toLocaleDateString('fr-FR');
    avoir.numeroFacture = avoir.ref_facture.numeroFacture;
    avoir.client = avoir.ref_facture.client;
    avoir.montantFacture = Number(avoir.ref_facture.totalTTC).toFixed(2) + " €";
    const diff = Number(avoir.ref_facture.totalTTC)-Number(avoir.net_a_deduire.value);
    avoir.diff = diff.toFixed(2) + " €";
    avoir.parFacture = factures.find((f) => f.id === avoir.facture_number)?.numeroFacture;
  });


  return (
    <Box sx={{ height: "100%" }}>
      <CustomTable
        data={table_avoirs}
        columns={columns}
        loading={loading}
        hover={false}
        onRowDoubleClick={(rowData: Facture) => handleDialogs("edit", rowData)}
        onSelectRows={onSelectRows}
        renderActionCell={(rowData, measuredRef) => (
          <AvoirTableActionsCell
            rowData={rowData}
            measuredRef={measuredRef}
            handleDialogs={handleDialogs}
          />
        )}
        rendersummary={() => <TableListSummary collection={table_avoirs}/>}
      />

      {/* Emit invoice  N° ${rowData?.numeroFacture} */}
      <EmitFactureDialog
        open={dialogsState.emit}
        title={"Emettre une facture"}
        contentText={`Êtes-vous sûr de vouloir émettre la facture à ${rowData?.client.name} ?`}
        factureDetails={{
          aReceiverEmail: rowData?.client.contact?.email ?? "",
        }}
        onConfirm={onConfirmEmit}
        onClose={() => handleDialogs("emit")}
        isButtonLoading={false}
        key="emit"
      />

      {/* Delete invoice */}
      <DeleteFactureDialog
        open={dialogsState.delete}
        title={"Supprimer une facture"}
        contentText={`Êtes-vous sûr de vouloir supprimer la facture N° ${rowData?.numeroFacture}  ?`}
        onConfirm={onConfirmDelete}
        onClose={() => handleDialogs("delete")}
        isButtonLoading={false}
        key="remove"
      />
    </Box>
  );
}

export default AvoirTable;
