import { createAsyncThunk } from "@reduxjs/toolkit";

export const retrieveAllUsers = createAsyncThunk(
  '[USER] retrieveAllUsers',
  async (_, thunk) => {
    try {
      return await (thunk.extra as { userQuery: any }).userQuery.getAll();
    } catch (error: any) {
      return thunk.rejectWithValue({ message: error.message ?? null });
    }
})