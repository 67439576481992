import { useSelector } from 'react-redux';

import PageHeaderBar from '../../../../App/app/common/layout/components/PageHeaderBar';
import { ViewCard } from '../../../../App/app/common/layout/components';
import { CustomTable, CustomTableToolbar } from '../../../../App/app/common/custom-table';
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { retrieveAllFactures,} from "../../../Domain/Application/use-cases";
import { updateDate } from "../../../Domain/Application/facture.slice";
import { selectAllFactures } from "../../../Domain/Application/selectors";
import { Stack } from '@mui/material';
import { DateRangeField } from '../../../../App/app/common/date-picker';
import { RootState } from '../../../../_config/redux';
import { useCallback, useEffect } from 'react';
import { validateDates } from '../../../../App/app/common/_helpers';
import { ColumnEntity } from '../../../../App/app/common/custom-table/Table.entities';
import { EntityId } from "@reduxjs/toolkit";
import { selectAllPaymentTypes } from '../../../../App/core/payment-type/selectors';
interface Props {
  onSelectRows(ids: EntityId[]): void;
}

function ListeEcritureTable({ onSelectRows }: Props) {
   
    const dispatch = useAppDispatch();
    const selectedDates = useSelector((state: RootState) => state.factures.dates);
    const status = useSelector((state: RootState) => state.factures.status);
    const factures = useSelector(selectAllFactures);


    const loadFactures = useCallback(
        function () {
          try {
            const { fromDate, toDate } = validateDates(selectedDates);
            dispatch(retrieveAllFactures({ status, from: fromDate, to: toDate }))
              .unwrap()
              .then(() => {
                 
              });
          } catch (err) {
            console.warn("Erreur lors de la récupération des factures", err);
          }
        },
        [dispatch, status, selectedDates]
      );

      useEffect(() => {
        loadFactures();
        return () => {
        
        };
      }, [loadFactures]);

    const title = 'Liste des écritures';
    const ecritures: object[] = [];

    const paymentTypes = useSelector(selectAllPaymentTypes);
      //console.log(factures);
    factures.forEach((facture) => {
        if(facture.reglement) {
            const reglement = JSON.parse(JSON.stringify(facture.reglement));

            if(reglement.payments && reglement.payments.length > 0) {
          if(facture.type !== "fournisseur") {
            ecritures.push({
              compte : "411000000",
              dateReglement : facture.dateDeFacturation,
              numeroFacture : facture.numeroFacture,
              client: facture.client.name,
              type: "Facture Client TTC",
              bank : "",
              montant : facture.totalTTC + ' € TTC'
            });
            ecritures.push({
              compte : "707000000",
              dateReglement : facture.dateDeFacturation,
              numeroFacture : facture.numeroFacture,
              client: facture.client.name,
              type: "Facture Client HT",
              bank : "",
              montant : (Number(facture.totalTTC) - Number(facture.totalTva)) +' € HT'
            });
            ecritures.push({
              compte : "445710000",
              dateReglement : facture.dateDeFacturation,
              numeroFacture : facture.numeroFacture,
              client: facture.client.name,
              type: "Facture Client TVA",
              bank : "",
              montant : facture.totalTva +' € TVA'
          });
          }
          else {
            ecritures.push({
              compte : "401000000",
              dateReglement : facture.dateDeFacturation,
              numeroFacture : facture.numeroFacture,
              client: facture.client.name,
              type: "Bon commande Fournisseur TTC",
              bank : "",
              montant : facture.totalTTC + ' € TTC'
            });
            ecritures.push({
              compte : "607100000",
              dateReglement : facture.dateDeFacturation,
              numeroFacture : facture.numeroFacture,
              client: facture.client.name,
              type: "Bon commande Fournisseur HT",
              bank : "",
              montant : (Number(facture.totalTTC) - Number(facture.totalTva)) +' € HT'
            });
            ecritures.push({
              compte : "445660000",
              dateReglement : facture.dateDeFacturation,
              numeroFacture : facture.numeroFacture,
              client: facture.client.name,
              type: "Bon commande Fournisseur TVA",
              bank : "",
              montant : facture.totalTva +' € TVA'
          });
          }

           
                reglement.payments.forEach((payment:any) => {
                    let datePayment = new Date(payment.payment_date);
                    let datePaymentFr = datePayment.toLocaleDateString("fr");//+" "+datePayment.toLocaleTimeString("fr-FR");


                    let codeCompta = paymentTypes.filter((type) =>  type.code === payment.payment_type )[0].compte;
                    let typeReglement = "Règlement "+paymentTypes.filter((type) =>  type.code === payment.payment_type )[0].wording;

                  
                    ecritures.push({
                        compte : codeCompta,
                        dateReglement : datePaymentFr,
                        numeroFacture : facture.numeroFacture,
                        client: facture.client.name,
                        type: typeReglement,
                        bank : payment.ribBank,
                        montant : payment.montant + ' €'
                    });
                    //ecritures par compte ici
                    // TTC client : 411000000
                    //  HT client : 707000000
                    // TVA client : 445710000

                    // TTC fourn : 401000000
                    // HT fourn : 607100000
                    // TVA FOurn : 445660000

                  
                });
            }
        }
    });
    const onDateUpdate = (field: string, value: string) => {
        if (value !== selectedDates) dispatch(updateDate(value));
      };

    const columns: ColumnEntity[] = [
        { field: 'compte', headerName: 'Compte', width: 150, sortable: true, resizable: true },
        { field: 'dateReglement', headerName: 'Date', width: 150, sortable: true, resizable: true },
        { field: 'numeroFacture', headerName: 'N° Facture', width: 150, sortable: true, resizable: true },
        { field: 'client', headerName: 'Client', width: 400, sortable: true, resizable: true },
        { field: 'type', headerName: 'Type', width: 150, sortable: true, resizable: true },
        { field: 'bank', headerName: 'Banque', width: 150, sortable: true, resizable: true },
        { field: 'montant', headerName: 'Montant', align:'right', width: 150, sortable: true, resizable: true },
      ];
 
    return (
        <>
            <ViewCard>
            <CustomTable
                data={ecritures}
                columns={columns}
                hover={false}
                onSelectRows={onSelectRows}
            />
            </ViewCard>
        </>
    );
}

export default ListeEcritureTable;
